const pagePath = {
  users: {
    uploadInsurance(orgId: number, backUrl?: string) {
      return `/user/upload/insurance?org_id=${orgId}${backUrl ? `&back=${backUrl}` : ""}`;
    },
  },
  error: {
    unSupported(isLab?: boolean) {
      return isLab ? "/hosting/unsupported" : "/unsupported";
    },
  },
  lab: {
    account() {
      return "/hosting/account";
    },
  },
  services: {
    item(slug: string) {
      return `/services/${slug}`;
    },
  },
  company: {
    invalidInvite() {
      return "/invalid-invite";
    },
  },
  organizations: {
    info(orgId?: number, orgSlug?: string, slug?: string) {
      if (slug) return `/organizations/${slug}`;
      return `/organizations/${orgSlug}-${orgId}`;
    },
    contracts(
      contractId: number,
      orgId: number,
      type: string,
      backUrl?: string,
    ) {
      return `/contracts/${contractId}?type=${type}&org_id=${orgId}${backUrl ? `&back=${backUrl}` : ""}`;
    },
    buySubscription(orgId: number, orgSlug: string, backUrl?: string) {
      return `/organizations/${orgSlug}-${orgId}/buy-subscription${backUrl ? `?back=${backUrl}` : ""}`;
    },
    access(slug: string, backUrl?: string, type?: string) {
      return `/organizations/${slug}/access-request?${backUrl ? `back=${backUrl}` : ""}${type ? `&type=${type}` : ""}`;
    },
  },
};

export default pagePath;
