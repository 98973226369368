import { Navigation } from "lucide-react";
import Link from "next/link";

import { getListingTypeName } from "@/components/common/constants";
import { LISTING_TYPE } from "@/components/common/constants";
import { ImageResponsive } from "@/components/image";
import { ButtonLink } from "@/components/ui/button";
import { addParams, cn, getHostingUrl, getListingPath } from "@/lib/utils";

export function ListingCard({ listing, className, userType }: any) {
  const mapParams = {
    api: 1,
    map_action: "map",
    destination: listing.location || "",
  } as any;
  const mapParamString = encodeURI(
    Object.keys(mapParams)
      .map((k) => `${k}=${mapParams[k]}`)
      .join("&"),
  );
  const footer = (
    <div className="mt-3 flex items-center justify-between gap-2 border-gold-200 text-title md:border-t md:pt-5">
      <div className="grow">
        <div className="p4-bold-mobile md:p3-bold-mobile line-clamp-1">
          <Link
            href={`${getHostingUrl(userType)}/organizations/details-${listing.org_id}`}
            target="_blank"
            className="p4-bold-mobile md:p3-bold-mobile line-clamp-1 text-coffee-900"
          >
            {listing.university_name}
          </Link>
        </div>

        <Link
          className="p4-mobile line-clamp-1 text-xxs text-title md:text-xs"
          href={`${getHostingUrl(userType)}/organizations/details-${listing.org_id}`}
          target="_blank"
        >
          {listing.org_name}
        </Link>
      </div>
      <div>
        <ButtonLink
          variant="secondary"
          className="flex h-[32px] w-[100px] shrink-0 items-center gap-2 text-coffee-800"
          href={`https://www.google.com/maps/dir/?${mapParamString}`}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p4-medium-mobile whitespace-nowrap">
            {(listing.distance || 0).toFixed(1)} miles
          </div>
          <Navigation size={14} strokeWidth={1} className="shrink-0" />
        </ButtonLink>
      </div>
    </div>
  );

  const EquipmentPricing = () => {
    return listing.requires_subscription &&
      listing.price_included_in_subscription ? (
      <div className="md:p3-bold-mobile text-emerald-500">
        Covered by Subscription
      </div>
    ) : (
      <div className="flex items-center gap-0.5 md:gap-1">
        <div className="h6-mobile md:h2 !text-emerald-500 md:font-semibold">
          {`$${listing.price}`}
        </div>
        <span className="p3-mobile md:h4 text-emerald-500">/h</span>
      </div>
    );
  };

  const ServicePricing = () => {
    return (
      <div className="flex items-center gap-0.5 md:gap-1">
        <div className="h6-mobile md:h4 !text-emerald-500 md:font-semibold">
          {listing.price === 0 ? (
            listing.price_unit === "Contact for pricing" ? (
              <span className="md:p3-bold-mobile !text-emerald-500">
                Contact for pricing
              </span>
            ) : (
              <span className="md:h2 !text-emerald-500">
                {listing.price_unit}
              </span>
            )
          ) : (
            <span>
              From{" "}
              <span className="md:h2 !text-emerald-500">${listing.price}</span>
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={cn(
        "block h-full bg-background p-4 hover:bg-coffee-50 md:rounded-lg md:pb-8",
        className,
      )}
    >
      <div className="relative flex h-full flex-col justify-between">
        <Link
          href={`${getHostingUrl(userType)}/${getListingPath(listing.type)}/${listing.slug}`}
          className="flex justify-between gap-3 md:h-full md:flex-col"
          target="_blank"
        >
          <div className="relative h-[182px] w-[111px] shrink-0 overflow-hidden rounded-lg md:h-40 md:w-full">
            <ImageResponsive
              className="h-full"
              src={addParams(listing.thumbnail, { width: 460 })}
              alt={listing.name}
              width="100%"
              height="100%"
            />
            <div
              className={cn(
                "absolute left-2 top-2 flex h-7 items-center justify-center rounded-sm bg-blue-50 px-2.5 text-xs font-medium text-emerald-black",
                listing.type == "equipment" ? "bg-gold-100" : "bg-coffee-100",
              )}
            >
              {getListingTypeName(listing.type)}
            </div>
          </div>

          <div className="flex h-full grow flex-col justify-between py-3 md:mt-4 md:py-0">
            <div className="flex justify-between gap-2">
              <div className="p3-bold-mobile md:p3-bold line-clamp-2 overflow-ellipsis text-emerald-900">
                {listing.name}
              </div>
            </div>
            {listing.requires_subscription && listing.user_subscribed && (
              <div className="p2 mt-2 text-sm md:mt-3">
                <div className="text-gold-500">
                  {listing.subscription_plan_name}
                </div>
              </div>
            )}
            <div className="p4 mt-2 line-clamp-2 h-[42px] text-sm text-coffee-800 md:mt-3">
              {listing.description}
            </div>
            <div className="relative flex grow items-end">
              {listing.type == LISTING_TYPE.EQUIPMENT ? (
                <EquipmentPricing />
              ) : (
                <ServicePricing />
              )}
            </div>
            <div>{footer}</div>
          </div>
        </Link>
        <div className="bottom-0 right-0 hidden w-[calc(100%-124px)] md:relative md:w-auto">
          {footer}
        </div>
      </div>
    </div>
  );
}
