import { ButtonLink } from "@/components/ui/button";
import pagePath from "@/src/helpers/page-path.helper";
import orgService from "@/src/services/org.service";
import userService from "@/src/services/user.service";
import useAuthStore from "@/stores/auth-store";

export function UserStatus() {
  const { user } = useAuthStore();
  let isVerified;
  const orgId = user?.referrer_org_id;
  if (typeof window !== "undefined") {
    isVerified = localStorage?.getItem("labProfileVerified") === "true";
  }

  const Banner = () => {
    const { data: userLabProfile } = userService.useLabProfile(orgId, "auto");

    const { data: orgData } = orgService.useById(orgId);
    const { data: subscriptionPlans } = orgService.useSubscriptionPlans(orgId);

    const { data: contract } = orgService.useDefaultContract(orgId);

    const { org } = orgData ?? {};

    const isLabHasSubscriptionPlan = subscriptionPlans?.length;
    const userStatus = userLabProfile?.status;
    if (!org) return null;

    const labName = `${org?.name}, ${org?.university?.name}`;
    if (
      [
        "new",
        "missing_insurance",
        "missing_subscription",
        "declined",
        "pending",
      ].includes(userStatus)
    ) {
      const href = (() => {
        switch (userStatus) {
          case "new":
            return pagePath.organizations.access(
              `${org.slug}-${org.id}`,
              pagePath.organizations.info(org?.id, org?.slug),
              userLabProfile.registration_type || contract?.contract_type,
            );
          case "missing_insurance":
            return pagePath.users.uploadInsurance(org.id);
          case "missing_subscription":
            return pagePath.organizations.buySubscription(org.id, org.slug);
          case "declined":
            return pagePath.organizations.access(
              `${org.slug}-${org.id}`,
              pagePath.organizations.info(org?.id, org?.slug),
              userLabProfile.registration_type || contract?.contract_type,
            );
          default:
            return "default";
        }
      })();

      if (userStatus == "pending") {
        const description = isLabHasSubscriptionPlan
          ? `Your account is currently under review by ${labName}. Your subscription will be activated once your account is approved.`
          : `Your account is currently under review by ${labName}. We will notify you once your account is approved.`;
        return (
          <div className="w-full bg-alert-background px-6 py-4 md:flex">
            <div className="text-emerald-900">
              <div className="p2-bold">Account Pending Verification</div>
              <div className="p3 mt-1">{description}</div>
            </div>
          </div>
        );
      }
      return (
        <div className="w-full items-center justify-between bg-[#FFE3DD] px-6 py-4 md:flex">
          <div className="text-emerald-900">
            <div className="p2-bold">Continue Account Setup</div>
            <div className="p3 mt-1">
              Your account setup is incomplete. Please click here to continue.
            </div>
          </div>
          <ButtonLink href={href} className="mt-3 w-full md:mt-0 md:w-[352px]">
            Continue Account Setup
          </ButtonLink>
        </div>
      );
    }
  };

  return orgId && !isVerified ? <Banner /> : null;
}
