import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import useSWRInfinite from "swr/infinite";

import { NoResultFound } from "@/components/common/no-result-found";
import { Notifications } from "@/components/common/notifications/notifications-popover";
import { SearchEquipment } from "@/components/home-header";
import { fetchEquipments } from "@/components/home-listings/api";
import { FilterPopver } from "@/components/home-listings/filter-popover";
import { ListingCard } from "@/components/home-listings/listing-card";
import { ListingTypeSelect } from "@/components/home-listings/listing-type-select";
import { EquipmentTags } from "@/components/home-listings/tags";
import { UserStatus } from "@/components/home-listings/user-status";
import { Loading } from "@/components/loading";
import useIsMobile from "@/lib/is-mobile";
import useOnScreen from "@/lib/use-onscreen";
import { cn, getUserType } from "@/lib/utils";
import useAuthStore from "@/stores/auth-store";

interface Props {
  filters: any;
}

const PAGE_SIZE = 12;

export function HomeListings(props: Props) {
  const [filters, setFilters] = useState(props.filters);
  const { user } = useAuthStore();
  const userType = getUserType(user);

  const router = useRouter();

  useEffect(() => {
    const type = router.query.type || "all";
    setFilters((prev: any) => ({ ...prev, type }));
  }, [router.query.type]);

  const ref = useRef<any>(null);
  const isVisible = useOnScreen(ref);

  const getKey = (pageIndex: number, previousPageData: any) => {
    const params = new URLSearchParams();
    if (filters.type !== "all") {
      params.append("type", filters.type);
    }

    // pagination
    params.append("page", String(pageIndex));
    params.append("limit", String(PAGE_SIZE));

    if (filters.text) {
      params.append("text", filters.text);
    }

    if (filters.tags) {
      Object.keys(filters.tags)
        .filter((key) => filters.tags[key])
        .map((tag) => params.append("tag_id", tag));
    }
    if (filters.manufacturers) {
      Object.keys(filters.manufacturers)
        .filter((key) => filters.manufacturers[key])
        .map((manufacturer) => params.append("manufacturer_id", manufacturer));
    }
    if (filters.labs) {
      Object.keys(filters.labs)
        .filter((key) => filters.labs[key])
        .map((orgSlug) => params.append("org_slug", orgSlug));
    }

    if (filters.minPrice) {
      params.append("min_price", filters.minPrice);
    }
    if (filters.maxPrice) {
      params.append("max_price", filters.maxPrice);
    }

    if (previousPageData && !previousPageData.listings) return null; // reached the end
    return `/listings/search?${params.toString()}`; // SWR key
  };

  const {
    data = [],
    size,
    setSize,
    isLoading,
  } = useSWRInfinite(getKey, fetchEquipments, {
    revalidateIfStale: true,
  });

  const isMobile = useIsMobile();

  const isEmpty = Boolean(!data?.[0]?.listings.length);
  const isReachingEnd =
    data && data[0]?.pagination?.total <= data.length * PAGE_SIZE;
  const isLoadingMore =
    data.length > 0 &&
    (isLoading || (size > 0 && data && typeof data[size - 1] === "undefined"));
  const loading = isLoading || isLoadingMore;

  useEffect(() => {
    if (isVisible && !isReachingEnd && !isLoading) {
      void setSize((prev) => prev + 1);
    }
  }, [isVisible, isReachingEnd, setSize, isLoading]);

  return (
    <div className="flex h-full flex-col items-center justify-between">
      <UserStatus />
      <div
        className={cn(
          "w-full max-w-screen-2xl flex-1 px-4 pb-20 pt-7",
          !user && "pt-14 md:pb-[102px] md:pt-[104px]",
        )}
      >
        {!!user && (
          <div className="flex items-center justify-between border-b border-b-coffee-600 pb-2">
            <h2 className="text-emerald-black">Home</h2>
            <div>
              <Notifications />
            </div>
          </div>
        )}

        <div
          className={cn(
            "z-10 mt-6 flex items-center gap-4 md:mt-8 md:justify-between",
            !user && "mt-0 md:mt-0",
          )}
        >
          <div className="shrink-0 grow *:w-full">
            <ListingTypeSelect filters={filters} setFilters={setFilters} />
          </div>
          <div className="hidden w-full md:block">
            <SearchEquipment
              placeholder={
                isMobile ? "Search" : "Search for equipment or service"
              }
              filters={filters}
              setFilters={setFilters}
            />
          </div>
          <FilterPopver filters={filters} setFilters={setFilters} />
        </div>
        <div className="mt-4 block w-full md:hidden">
          <SearchEquipment
            placeholder={
              isMobile ? "Search" : "Search for equipment or service"
            }
            filters={filters}
            setFilters={setFilters}
          />
        </div>
        <div className="mt-3">
          <EquipmentTags setFilters={setFilters} filters={filters} />
        </div>

        <ul className="xs:px-4 -mx-4 mt-3 grid auto-rows-auto grid-cols-1 divide-y divide-gold-200 md:mx-0 md:mt-7 md:grid-cols-2 md:gap-6 md:divide-y-0 lg:gap-8 xl:grid-cols-4">
          {data.map(({ listings }) =>
            listings.map((listing: any) => (
              <li key={listing.slug}>
                <ListingCard listing={listing} userType={userType} />
              </li>
            )),
          )}
        </ul>
        {isEmpty && !loading && (
          <div className="mt-24">
            <NoResultFound />
          </div>
        )}
        <div ref={ref} />
        {loading && <Loading className="my-10" />}
      </div>
    </div>
  );
}
