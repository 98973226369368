import useSWR from "swr";

import { superFetchData } from "@/lib/utils";

const userService = {
  useInfo: () => {
    return useSWR(`/users/info`, superFetchData);
  },
  useLabProfile: (orgId: number, type?: "equipment" | "service" | "auto") => {
    return useSWR(
      `/users/lab-profile?lab_id=${orgId}${type ? `&prefer_booking_type=${type}` : ""}`,
      superFetchData,
      {
        onSuccess: (data) => {
          if (data.status === "verified") {
            localStorage.setItem("labProfileVerified", "true");
          }
        },
      },
    );
  },
  useCreateSubscription: (orgId: number) => {
    return useSWR(`/users/subscriptions?org_id=${orgId}`, superFetchData);
  },
  useSubscription: (orgId: number) => {
    return useSWR(`/users/subscriptions?org_id=${orgId}`, superFetchData);
  },
};

export default userService;
