import { SlidersHorizontal, X } from "lucide-react";
import { useState } from "react";

import { defaultFilters, Filters } from "@/components/home-listings/interface";
import { MultiSelectFilter } from "@/components/home-listings/multiselect-filter";
import {
  fetchManufaturers,
  fetchOrgs,
  fetchTags,
} from "@/components/new-equipment/api";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";

interface Props {
  filters: Filters;
  setFilters: (filters: Filters) => void;
}

export function FilterPopver(props: Props) {
  const [open, setOpen] = useState(false);
  const [tempFilters, setTempFilters] = useState(props.filters);

  // Init temp filters
  function onOpenChange(nextOpen: boolean) {
    if (nextOpen) {
      setTempFilters(props.filters);
    }
    setOpen(nextOpen);
  }

  function clearFilters() {
    props.setFilters(defaultFilters);
    setOpen(false);
  }

  function applyFilters() {
    props.setFilters(tempFilters);
    setOpen(false);
  }

  const tags = props.filters.tags || {};
  const manufacturers = props.filters.manufacturers || {};
  const labs = props.filters.labs || {};
  const filterNum =
    Object.keys(tags).filter((key) => tags[key]).length +
    Object.keys(manufacturers).filter((key) => manufacturers[key]).length +
    Object.keys(labs).filter((key) => labs[key]).length +
    (props.filters.text ? 1 : 0) +
    (props.filters.minPrice ? 1 : 0) +
    (props.filters.maxPrice ? 1 : 0) +
    (props.filters.type !== "all" ? 1 : 0);

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          className={cn(
            "p1-bold h-11 rounded-[56px] bg-interface-white px-5 font-bold text-coffee-500 md:px-8",
            open &&
              "bg-emerald-600 text-interface-white hover:bg-coffee-100 hover:text-coffee-300",
          )}
          aria-label="Filters"
        >
          <SlidersHorizontal strokeWidth={1} className="mr-2" size={24} />
          <span className="hidden md:inline">Filters</span>
          {filterNum > 0 && (
            <Badge
              className={cn(
                "ml-2 bg-coffee-500 text-gold-50",
                open && "bg-background text-emerald-600",
              )}
            >
              {filterNum}
            </Badge>
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent className="relative top-0 h-full w-screen p-0 pb-[123px] shadow-[0_39px_85px_0_rgba(35,45,42,0.15)] md:min-h-[500px] md:w-[601px]">
        <div className="flex items-center justify-between px-6 py-4">
          <h6 className="text-emerald-black">Filters</h6>
          <X onClick={clearFilters} className="cursor-pointer">
            Clear Filters
          </X>
        </div>
        <Separator className="bg-coffee-200" />
        <div className="px-6 pb-0 pt-4 md:h-[500px] md:pb-4">
          <div className="overflow-y-auto">
            <div className="h7 text-emerald-black">Type</div>
            <div className="mt-3 flex gap-3">
              <Button
                variant="outline"
                className={cn(
                  "h-11 w-1/3",
                  tempFilters.type === "service" &&
                    "bg-emerald-black text-background",
                )}
                onClick={() =>
                  setTempFilters((prev) => ({
                    ...prev,
                    type:
                      tempFilters.type !== "service" ? "service" : undefined,
                  }))
                }
              >
                Service
              </Button>
              <Button
                variant="outline"
                className={cn(
                  "h-11 w-1/3",
                  tempFilters.type === "equipment" &&
                    "bg-emerald-black text-background",
                )}
                onClick={() =>
                  setTempFilters((prev) => ({
                    ...prev,
                    type:
                      tempFilters.type !== "equipment"
                        ? "equipment"
                        : undefined,
                  }))
                }
              >
                Equipment
              </Button>
              <Button
                variant="outline"
                className={cn(
                  "h-11 w-1/3",
                  tempFilters.type === "scientific_process" &&
                    "bg-emerald-black text-background",
                )}
                onClick={() =>
                  setTempFilters((prev) => ({
                    ...prev,
                    type:
                      tempFilters.type !== "scientific_process"
                        ? "scientific_process"
                        : undefined,
                  }))
                }
              >
                Scientific Process
              </Button>
            </div>
          </div>
          <div className="mt-4">
            <div className="p4-medium text-coffee-500">Tags</div>
            <div className=" mt-2">
              <MultiSelectFilter
                setFilters={setTempFilters}
                filters={tempFilters}
                fetchFn={fetchTags}
                name="tags"
                path="tags"
              />
            </div>
            <div className="p4-medium mt-5 text-coffee-500">Manufacturers</div>
            <div className="mt-2">
              <MultiSelectFilter
                setFilters={setTempFilters}
                filters={tempFilters}
                fetchFn={fetchManufaturers}
                name="manufacturers"
                path="manufacturers"
              />
            </div>
            <div className="p4-medium mt-5 text-coffee-500">Lab</div>
            <div className="mt-2">
              <MultiSelectFilter
                setFilters={setTempFilters}
                filters={tempFilters}
                fetchFn={fetchOrgs}
                name="labs"
                path="orgs/search"
                params={{ type: "university" }}
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="p4-medium text-coffee-500">Price range</div>
            <div className="mt-2 flex items-center gap-1">
              <Input
                value={tempFilters.minPrice}
                placeholder="E.g. $300"
                onChange={(e) =>
                  setTempFilters((prev) => ({
                    ...prev,
                    minPrice: e.target.value,
                  }))
                }
              />
              -
              <Input
                value={tempFilters.maxPrice}
                onChange={(e) =>
                  setTempFilters((prev) => ({
                    ...prev,
                    maxPrice: e.target.value,
                  }))
                }
                placeholder="E.g. $500"
              />
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 flex w-full gap-4 px-6 pb-12 pt-4">
          <Button onClick={clearFilters} variant="secondary" className="w-1/2">
            Clear
          </Button>
          <Button onClick={applyFilters} className="w-1/2">
            Apply Filters
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
